import { SET_CURRENT_DEPARTMENT } from '../actions/types';

interface DepartmentState {
  department: object;
}

const initialState: DepartmentState = {
  department: {}
};

const departmentReducer = (state: DepartmentState = initialState, action: any): DepartmentState => {
  switch (action.type) {
    case SET_CURRENT_DEPARTMENT:
      return {
        ...state,
        department: action.payload,
      };
    default:
      return state;
  }
};

export default departmentReducer;
